import { useState } from "react";
import emailjs from "emailjs-com";
import "./App.css";
import "./index.css";

function App() {
  const [rows, setRows] = useState([
    {
      name: "",
      hours: "",
      sales: {
        '50-69': "",
        '70-79': "",
        '80-89': "",
        '90-99': "",
        '100-119': "",
        '120-189': "", // Added new product
        '190+': "",
        'Streaming bundle': "",
        '5G': "",
        '4G': ""
      }
    }
  ]);
  const [results, setResults] = useState([]);
  const [totalHours, setTotalHours] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [averageSales, setAverageSales] = useState(0);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [event, setEvent] = useState("");

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        name: "",
        hours: "",
        sales: {
          '50-69': "",
          '70-79': "",
          '80-89': "",
          '90-99': "",
          '100-119': "",
          '120-189': "", // Added new product
          '190+': "",
          'Streaming bundle': "",
          '5G': "",
          '4G': ""
        }
      }
    ]);
  };

  const handleDeleteRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  const handleChange = (index, field, value) => {
    const newRows = [...rows];
    if (field in newRows[index].sales) {
      newRows[index].sales[field] = value;
    } else {
      newRows[index][field] = value;
    }
    setRows(newRows);
  };

  const handleCalculate = () => {
    let totalHours = 0;
    let totalSales = 0;

    const newResults = rows.map((row) => {
      const hours = parseFloat(row.hours) || 0;
      const sales = Object.values(row.sales).reduce(
        (sum, sale) => sum + (parseFloat(sale) || 0),
        0
      );

      totalHours += hours;
      totalSales += sales;

      const averageSales = hours > 0 ? (sales / hours).toFixed(2) : 0;
      const salesWithDefault = Object.fromEntries(
        Object.entries(row.sales).map(([key, value]) => [key, value || "0"])
      );

      return {
        name: row.name || "0",
        hours: row.hours || "0",
        sales: salesWithDefault,
        averageSales
      };
    });

    setResults(newResults);
    setTotalHours(totalHours);
    setTotalSales(totalSales);
    setAverageSales(totalHours > 0 ? (totalSales / totalHours).toFixed(2) : 0);
  };

  const generateResultsTable = () => {
    return `
      <table style="border-collapse: collapse; width: 100%;">
        <tr>
          <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Navn</th>
          <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Salg</th>
          <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Timer</th>
          <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Gennemsnitlige Salg pr. Time</th>
        </tr>
        ${results
          .map(
            (result) => `
              <tr>
                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${result.name}</td>
                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                  ${Object.values(result.sales).join(" / ")}
                </td>
                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${result.hours}</td>
                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${result.averageSales}</td>
              </tr>
            `
          )
          .join("")}
        <tr>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"><strong>Total</strong></td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"><strong>${totalSales}</strong></td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"><strong>${totalHours}</strong></td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"><strong>${averageSales}</strong></td>
        </tr>
      </table>
    `;
  };

  const generateClipboardText = () => {
    const totals = results.reduce(
      (acc, result) => {
        Object.entries(result.sales).forEach(([key, value]) => {
          acc[key] += parseInt(value) || 0;
        });
        return acc;
      },
      {
        '50-69': 0,
        '70-79': 0,
        '80-89': 0,
        '90-99': 0,
        '100-119': 0,
        '120-189': 0, // Added new product
        '190+': 0,
        'Streaming bundle': 0,
        '5G': 0,
        '4G': 0
      }
    );

    const totalSales = Object.values(totals).reduce((sum, sale) => sum + sale, 0);

    return `**Status ${event} d. ${new Date().toLocaleDateString('da-DK')}**

50-69/70-79/80-89/90-99/100-119/120-189/190+/Streaming bundle/5G/4G:

${results
      .map(
        (result) =>
          `${result.name}: ${Object.values(result.sales).join("/")}`
      )
      .join("\n")}
        
I alt: ${Object.values(totals).join("/")} = ${totalSales} stk.
    `;
  };

  const handleCopyToClipboard = () => {
    const textToCopy = generateClipboardText();
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        setMessage("Salgstal blev kopieret til udklipsholder!");
      },
      (err) => {
        setMessage("Kunne ikke kopiere salgstal til udklipsholder.");
        console.error("Failed to copy text: ", err);
      }
    );
  };

  const handleSendEmail = async () => {
    setLoading(true);
    const resultsTable = generateResultsTable();
    const templateParams = {
      to_email: email,
      resultsTable: resultsTable
    };

    try {
      const response = await emailjs.send(
        "service_50a3msq",
        "template_82gabgo",
        templateParams,
        "eevvcmFvgav4jlpEo"
      );

      if (response.status === 200) {
        setMessage("E-mailen blev sendt succesfuldt!");
        console.log("SUCCESS!", response.status, response.text);
      } else {
        throw new Error("Failed to send email");
      }
    } catch (error) {
      setMessage("Fejl ved afsendelse af e-mailen. Prøv igen.");
      console.error("FAILED...", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendToDatabase = async () => {
    const date = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format

    const dataToSend = results.map(result => ({
        name: result.name || "N/A",
        hours: parseInt(result.hours, 10) || 0,
        sales: {
            '50-69': parseInt(result.sales['50-69'], 10) || 0,
            '70-79': parseInt(result.sales['70-79'], 10) || 0,
            '80-89': parseInt(result.sales['80-89'], 10) || 0,
            '90-99': parseInt(result.sales['90-99'], 10) || 0,
            '100-119': parseInt(result.sales['100-119'], 10) || 0,
            '120-189': parseInt(result.sales['120-189'], 10) || 0,
            '190+': parseInt(result.sales['190+'], 10) || 0,
            'Streaming bundle': parseInt(result.sales['Streaming bundle'], 10) || 0,
            '5G': parseInt(result.sales['5G'], 10) || 0,
            '4G': parseInt(result.sales['4G'], 10) || 0
        },
        averageSales: parseFloat(result.averageSales) || 0,
        event: event || "Unknown Event",
        date: date
    }));

    console.log("Sending data to server:", JSON.stringify(dataToSend, null, 2)); // Log the data being sent

    try {
        const response = await fetch('https://fm.cmrelations.dk/api/sales', { // Update URL here
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)
        });

        if (!response.ok) {
            throw new Error('Failed to send data');
        }

        setMessage('Data successfully sent to the database.');
    } catch (error) {
        setMessage('Error sending data to the database.');
        console.error('Error:', error);
    }
};


  const handleSendAll = async () => {
    await handleSendEmail();
    await handleSendToDatabase();
  };

  return (
    <div className="container">
      <h1>Salgstal - CM Relation Fieldmarketing</h1>
      <div className="event-input">
        <h2>Event:</h2>
        <input
          type="text"
          value={event}
          onChange={(e) => setEvent(e.target.value)}
          placeholder="Indtast event lokation"
        />
      </div>
      <br></br><br></br>
      <table className="input-table">
        <thead>
          <tr>
            <th>Navn</th>
            <th>Timer</th>
            <th>50-69</th>
            <th>70-79</th>
            <th>80-89</th>
            <th>90-99</th>
            <th>100-119</th>
            <th>120-189</th> {/* Added new product */}
            <th>190+</th>
            <th>Streaming bundle</th>
            <th>5G</th>
            <th>4G</th>
            <th>Handling</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  className="name-input"
                  value={row.name}
                  onChange={(e) => handleChange(index, "name", e.target.value)}
                  placeholder="Navn"
                />
              </td>
              <td>
                <input
                  type="number"
                  className="hours-input"
                  value={row.hours}
                  onChange={(e) => handleChange(index, "hours", e.target.value)}
                  placeholder="Timer"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.sales['50-69']}
                  onChange={(e) => handleChange(index, "50-69", e.target.value)}
                  placeholder="50-69"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.sales['70-79']}
                  onChange={(e) => handleChange(index, "70-79", e.target.value)}
                  placeholder="70-79"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.sales['80-89']}
                  onChange={(e) => handleChange(index, "80-89", e.target.value)}
                  placeholder="80-89"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.sales['90-99']}
                  onChange={(e) => handleChange(index, "90-99", e.target.value)}
                  placeholder="90-99"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.sales['100-119']}
                  onChange={(e) => handleChange(index, "100-119", e.target.value)}
                  placeholder="100-119"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.sales['120-189']} // Added new product
                  onChange={(e) => handleChange(index, "120-189", e.target.value)}
                  placeholder="120-189"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.sales['190+']}
                  onChange={(e) => handleChange(index, "190+", e.target.value)}
                  placeholder="190+"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.sales['Streaming bundle']}
                  onChange={(e) => handleChange(index, "Streaming bundle", e.target.value)}
                  placeholder="Streaming bundle"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.sales['5G']}
                  onChange={(e) => handleChange(index, "5G", e.target.value)}
                  placeholder="5G"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.sales['4G']}
                  onChange={(e) => handleChange(index, "4G", e.target.value)}
                  placeholder="4G"
                />
              </td>
              <td>
                <button onClick={() => handleDeleteRow(index)}>Slet</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleAddRow}>Tilføj række</button>
      <br /><br />
      <button className="sendmail" onClick={handleCalculate}>Få salgstal</button>

      {results.length > 0 && (
        <>
          <table className="results-table">
            <thead>
              <tr>
                <th>Navn</th>
                <th>Salg</th>
                <th>Timer</th>
                <th>Gennemsnitlige Salg pr. Time</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr key={index}>
                  <td>{result.name}</td>
                  <td>{Object.values(result.sales).join(" / ")}</td>
                  <td>{result.hours}</td>
                  <td>{result.averageSales}</td>
                </tr>
              ))}
              <tr>
                <td><strong>Total</strong></td>
                <td><strong>{totalSales}</strong></td>
                <td><strong>{totalHours}</strong></td>
                <td><strong>{averageSales}</strong></td>
              </tr>
            </tbody>
          </table>
          <br />
          <button
            className="sendmail"
            onClick={handleSendAll}
            disabled={loading}
          >
            {loading ? "Sender..." : "Send salgstal til database"}
          </button><br></br>
          <button onClick={handleCopyToClipboard}>Kopier til udklipsholder</button>
        </>
      )}
      {message && <p className={`message ${message.includes('succesfuldt') ? 'success' : 'error'}`}>{message}</p>}
    </div>
  );
}

export default App;
